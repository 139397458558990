import React from "react"
import clsx from "clsx"
import Container from "@ecom/ui/components/Container"

import * as styles from "./habenskiyfund.module.scss"

export function HabenskiyFundV2() {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <div className={styles.title}>Фонд Константина Хабенского</div>
        <div className={styles.text}>
          <p>
            Делайте по Халве каждый месяц от 5 покупок на общую сумму от 10 000 рублей (Правило
            «5х10») и присоединяйтесь к доброй миссии Совкомбанка и Фонда Константина Хабенского! За
            покупки Вы получите все бонусы Халвы, а Банк перечислит за Вас 200 рублей в
            благотворительный фонд. Собранные средства пойдут на лечение детей с онкологическими
            заболеваниями, а также другими заболеваниями головного и спинного мозга.
          </p>
        </div>
        <div className={styles.title} style={{ marginBottom: 24 }}>
          О Фонде Константина Хабенского
        </div>
        <div className={clsx(styles.text, styles.bottomText)}>
          <p>
            Фонд с 2008 года помогает детям справиться с действительно сложными заболеваниями и
            сохранить жизнь. Он оказывает финансовую поддержку при обследовании и лечении
            онкологических, а также других заболеваний головного и спинного мозга.
          </p>
          <p>Вместе мы сможем гораздо больше!</p>
        </div>
      </Container>
    </section>
  )
}
